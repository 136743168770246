export const API_BASE_URL = decodeURIComponent(process.env.REACT_APP_API_BASE_URL);
export const AUTH_BASE_URL = decodeURIComponent(process.env.REACT_APP_AUTH_BASE_URL);
export const APP_CLIENT_ID = process.env.REACT_APP_APP_CLIENT_ID;
export const APP_CLIENT_SECRET = process.env.REACT_APP_APP_CLIENT_SECRET;
export const REDIRECT_URI = decodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
export const LOGIN_BASE_URL = decodeURIComponent(process.env.REACT_APP_LOGIN_BASE_URL);
export const LOGIN_URL = decodeURIComponent(process.env.REACT_APP_LOGIN_URL);
export const LOGOUT_URL = decodeURIComponent(process.env.REACT_APP_LOGOUT_URL);

export const idTokenKey = 'id_token';
export const accessTokenKey = 'access_token';
export const refreshTokenKey = 'refresh_token';
export const tokenTypeKey = 'token_type';
export const tokenExpiredAt = 'token_expire_at';
export const currentUserKey = 'user';
export const ERROR_STATUS = "ERROR";