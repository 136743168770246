import * as API from "./axios";
import { API_BASE_URL as BASE_URL} from '../const';

export async function getTMCAMonthlyReport(type, year, month) {
  try {
    const result = await API.init().get(BASE_URL + `/monthly-report?type=${type}&year=${year}&month=${month}`);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    return buildErrorResponse(err)
  }
};

export async function findMonthlyReport(type, year, month) {
  try {
    const result = await API.init().get(BASE_URL + `/monthly-report/find?type=${type}&year=${year}&month=${month}`);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    return buildErrorResponse(err)
  }
};

export async function findWeeklyReport(type, year, month) {
  try {
    const result = await API.init().get(BASE_URL + `/weekly-report/find?type=${type}&year=${year}&month=${month}`);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    return buildErrorResponse(err)
  }
};

export async function getChannels() {
  try {
    const result = await API.init().get(BASE_URL + `/asure-service/channels`);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    return buildErrorResponse(err)
  }
};

export async function searchService(id, channel) {
  try {
    const result = await API.init().get(BASE_URL + `/asure-service?id=${id}&channel=${channel}`);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    return buildErrorResponse(err)
  }
};

export async function updateState(data) {
  try {
    const result = await API.init().post(BASE_URL + `/asure-service/update-state`, data);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    return buildErrorResponse(err)
  }
};

export async function closeService(data) {
  try {
    const result = await API.init().post(BASE_URL + `/asure-service/close`, data);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    return buildErrorResponse(err)
  }
};

export async function ping() {
  try {
    const result = await API.init().post(BASE_URL + `/hello`);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    return buildErrorResponse(err)
  }
};

function buildErrorResponse (err) {
  let res = err?.response;
  return  {
    'message': res?.data?.message,
    'status': 'ERROR',
    'statusCode': res?.status
  }
}

const AsureServices = {
  getTMCAMonthlyReport,
  findWeeklyReport,
  findMonthlyReport,
  searchService,
  updateState,
  getChannels,
  ping,
  closeService
};

export default AsureServices;