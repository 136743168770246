import axios from 'axios';
import { API_BASE_URL } from '../const';
import { getIdToken } from './auth.service';
import * as AuthService from './auth.service';

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const init = () => {
  const instance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
       'Content-Type': 'application/json',
       'token': getIdToken(),
    },
  });

  instance.interceptors.request.use(function (config) {
    let token = AuthService.getIdToken();
    if (config?.headers?.token && config?.headers?.token === token) {
      let tokenParsed = parseJwt(token);
      const d = new Date();
      let exp = tokenParsed?.exp * 1000;
      if (d >= new Date(exp)) {
        console.log("token expired ! Need to login again!");
        AuthService.logout();
      }
    }
    return config;
  }, function (error) {
    if(error?.response?.status === 403 || error?.response?.status === 401) { 
      console.log("Redirection needed !"); 
    }
    return Promise.reject(error);
  });
  return instance;
};

