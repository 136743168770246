import { Spinner, Table } from 'reactstrap';
import './WeeklyReport.css';
import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import AsureServices from '../../../services/asure.service';
var FileSaver = require('file-saver');
const reportTypes = ['TMCA', 'HMCA', 'GMA', 'MMAL'];
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const years = ['2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032' ,'2033'];

const initialState = {
  selectedType: 'TMCA',
  selectedYear: '',
  selectedMonth: '',
  returnType: '',
  returnPeriod: '',
  returnStatus: '',
  loading: false,
  reports: [],
  status: ''
};

const REPORT_STATUS = {
  AVAILABLE: "READY",
  UNAVAILABLE: "NOT READY"
};

function WeeklyReport() {
  const [reportState, setReportState] = useState(initialState);

  useEffect(() => {
    const d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth() < 1 ? 1 : d.getMonth();
    if (parseInt(month) < 10) month = '0' + parseInt(month);
    setReportState({...reportState, selectedMonth: month, selectedYear: year});
  }, []);

  const onClickRunReport = () => {
    setReportState({...reportState, report:'', loading: true});
    AsureServices.findWeeklyReport(reportState.selectedType, reportState.selectedYear, reportState.selectedMonth).then(res => {
      if (res.status === REPORT_STATUS.AVAILABLE)
        setReportState({...reportState, loading: false, reports: res.data, status: res.status});
      else
        setReportState({...reportState, loading: false, reports: [], status: res.status});
  })};

  const onChangeMonth = (event) => {
    let val = event.target.value;
    if (parseInt(val) < 10) val = '0' + parseInt(val);
    setReportState({...reportState, selectedMonth: val});
  }

  const downloadReport = (index) => {
    var blob = new Blob([reportState.reports[index].content], {type : 'text/csv'});
    FileSaver.saveAs(blob, `${reportState.reports[index].key}` );
  }

  return (
    <div className="provision-device-container">
      <form>
        <h2 className="weekly-report-header">Weekly Report</h2>
        <br/>
        <div>
          <label className="weekly-report-tbl-label">Type: </label>
          <select className="weekly-report-tbl-select" onChange={e => setReportState({...reportState, selectedType: e.target.value})}>
            {
              reportTypes?.map(t => <option key={t}>{t}</option>)
            }
          </select>
          <label className="weekly-report-tbl-label">Year: </label>
          <select className="weekly-report-tbl-select" value={reportState.selectedYear} onChange={e => setReportState({...reportState, selectedYear: e.target.value})}>
            {
              years?.map(y => <option value={y} key={y}>{y}</option>)
            }
          </select>
          <label className="weekly-report-tbl-label">Month: </label>
          <select className="weekly-report-tbl-select" value={reportState.selectedMonth} onChange={onChangeMonth} >
            {
              months?.map(m => <option value={m} key={m}>{m}</option>)
            }
          </select>
          <button className="btn btn-primary weekly-report-tbl-button" type='button' onClick={onClickRunReport}>Search</button>
        </div>
      </form>
      {
        reportState.loading && <Spinner animation="border" role="status" className="weekly-report-spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {
        !reportState.loading && reportState.reports && reportState.reports.length > 0 && 
        <div>
            <br/>
            <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th>Week</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                  reportState.reports?.map((item, index) => {
                    return (
                        <tr>
                          <td>{item.key}</td>
                          <td><input className="btn btn-primary weekly-report-row" onClick={ ()=> downloadReport(index)} type={'button'} value='Download'/></td>
                        </tr>
                    )
                  })
                }
                </tbody>
            </Table>
        </div>
      }
      {
        !reportState.loading && (reportState.status === REPORT_STATUS.UNAVAILABLE
            || (reportState.status === REPORT_STATUS.AVAILABLE && reportState.reports.length === 0)) &&
        <div>
            <br/>
            <Alert variant="light">
              No reports are ready for the selected period.
            </Alert>
        </div>
      }
    </div>
  );
}

export default WeeklyReport;
