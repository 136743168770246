import moment from "moment";

export const convertStartDatetimeString = (date) => {
    
    // Convert the given date string to the start of the day (00:00:00) in UTC
    return moment(date, "YYYY-MM-DD").startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}

export const convertEndDatetimeString = (date) =>  {

    // Convert the given date string to the end of the day (23:59:59) in UTC
    return moment(date, "YYYY-MM-DD").endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}