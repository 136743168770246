import React, {useState} from "react";
import {Spinner, Table} from "reactstrap";
import './SearchReports.css';
import {downloadCaseReport, searchCaseReports} from "../../../services/case-reports.service";

const initialState = {
    loading: false,
    searchTermCaseID: '',
    searchTermVIN: '',
    searchTermMSISDN: '',
    results: [],
    errorMessage: ''
}

function SearchReports() {

    const [state, setState] = useState(initialState)

    const onSearchInputCaseIDChanged = (event) => {
        setState({...state, searchTermCaseID: event.target.value.trim()})
    }

    const onSearchInputVINChanged = (event) => {
        setState({...state, searchTermVIN: event.target.value.trim()})
    }

    const onSearchInputMSISDNChanged = (event) => {
        setState({...state, searchTermMSISDN: event.target.value.trim()})
    }

    const onSearchClicked = () => {
        if (state.searchTermCaseID === '' && state.searchTermVIN === '' && state.searchTermMSISDN === '') {
            setState({...state, errorMessage: "Invalid search.", results: []})
            return
        }

        setState({...state, errorMessage: '', loading: true, results: []})

        searchCaseReports(state.searchTermCaseID, state.searchTermVIN, state.searchTermMSISDN)
            .then(res => {

                if (res.length <= 0) {
                    setState({...state, errorMessage: "Case report not available. Please check case report name and retry.", results: []})
                    return
                }

                let results = res.map((item) => {
                    return {
                        "id": item.id.replace("CASE#", ""),
                        "channel": item.channel,
                        "vin": item.vin,
                        "msisdn": item.msisdn,
                        "date": formatDate(item.date),
                        "service": item.service
                    }
                })

                setState({...state, errorMessage: '', loading: false, results: results})
            })
    }

    const onDownloadCaseReportClicked = (caseId) => {

        caseId = caseId.substring(caseId.indexOf('#') + 1)

        downloadCaseReport(caseId)
            .then(res => {
                window.open(res.download_url, '_blank', 'noopener,noreferrer');
            })
    }

    const formatDate = (inputDate) => {
        // Extract year, month, and day from the inputDate
        const year = inputDate.substring(0, 4);
        const month = inputDate.substring(4, 6);
        const day = inputDate.substring(6, 8);

        return year + '-' + month + '-' + day;
    }

    return (
        <div className="provision-device-container">
            <form>
                <h2 className="report-header">Case Reports</h2>
                <br/>
                <label>Case ID
                    <input type='text' className="report-search-box"
                           placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                           onChange={onSearchInputCaseIDChanged}/>
                </label>
                <label>MSISDN
                    <input type='text' className="report-search-box"
                           placeholder="61XXXXXXXXX"
                           onChange={onSearchInputMSISDNChanged}/>
                </label>
                <label>VIN
                    <input type='text' className="report-search-box"
                           placeholder="XXXXXXXXXXXXXXXXX"
                           onChange={onSearchInputVINChanged}/>
                </label>

                <input type='button' value={'Search'} className="btn btn-primary report-button"
                       onClick={onSearchClicked}/>
            </form>

            {
                state.loading &&
                <Spinner animation="border" role="status" className="report-spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            }

            {
                state.errorMessage !== '' && <p>{state.errorMessage}</p>
            }

            {
                state.results && state.results.length > 0 && !state.loading &&
                <Table striped bordered hover responsive>
                    <thead>
                    <tr>
                        <th>Case ID</th>
                        <th>Published</th>
                        <th>Channel</th>
                        <th>Service</th>
                        <th>MSISDN</th>
                        <th>VIN</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        state.results.map((item, index) =>
                            <tr>
                                <td>{item.id}</td>
                                <td>{item.date}</td>
                                <td>{item.channel}</td>
                                <td>{item.service}</td>
                                <td>{item.msisdn}</td>
                                <td>{item.vin}</td>
                                <td><input onClick={() => {
                                    onDownloadCaseReportClicked(item.id)
                                }} className="btn btn-primary report-button-download" type={'button'} value='Download'/>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            }
        </div>
    )
}

export default SearchReports;
