import './Signout.css';
import * as AuthService from "../../services/auth.service";

function Signout() {
    const onClickSignOut = () => {
        AuthService.logout();
    }

    return (
        <input type="button" className="btn btn-block btn-login signout-btn-logout" placeholder="Sign Out" value={'Sign Out'} onClick={onClickSignOut}/>
    );
}

export default Signout;
