import { Table, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import './SearchAsure.css'
import AsureServices from '../../services/asure.service';

// const channelList = ['TMCA', 'GMA', 'HMCA'];

const initState = {
  channel: '',
  id: '',
  loading: false,
  result: [],
  message: ''
}

function SearchAsure() {

  const [serviceData, setServiceDate] = useState(initState);
  const [channelList, setChannelList] = useState([]);

  useEffect(() => {
    AsureServices.getChannels().then(res => {
      setChannelList(res.data?.records);
      setServiceDate({...serviceData, channel: res.data?.records[0][0]?.stringValue})
    })
  }, []);

  const onClickSearch = () => {
    setServiceDate({...serviceData, loading: true, result: [], message: ''})
    AsureServices.searchService(serviceData.id, serviceData.channel).then(res => {
      let data = res.data?.records;
      let services = [];
      let message = '';
      if (!data || (data && data.length === 0 )) {
        message = 'Service not found.';
      }
      for (let row of data){
        let service = {
          id: '',
          channel : '',
          state: ''
        };
        service.id = row[0].stringValue;
        service.channel = row[1].stringValue;
        service.state = row[2].stringValue;
        services.push(service);
      }
      setServiceDate({...serviceData, loading: false, result: services, message})
    });
  }
  
  const onClickForceClose = () => {
    let data = {
      id: serviceData.id,
      channel: serviceData.channel,
      state: 'CLOSED'
    }
    AsureServices.updateState(data).then(res => {
      onClickSearch();
    })
  }

  const onClickClose = () => {
    let data = {
      serviceId: serviceData.id,
      channel: serviceData.channel
    }
    AsureServices.closeService(data).then(res => {
      onClickSearch();
    })
  }

  return (
    <div >
      <form>
        <h2 style={{backgroundColor: 'rgb(197, 217, 255)', marginBottom: '1rem', color: 'black'}}>Search Asure Service</h2>
        <br/>
        <div>
          <label style={{marginRight: '10px', color: 'black'}}>Channel: </label>
          <select value={serviceData.channel} onChange={e => setServiceDate({...serviceData, channel: e.target.value})} style={{marginRight: '10px', height: '30px', minWidth: '70px'}}>
            {
              channelList?.map(c => c.map(channel => <option value={channel.stringValue} key={"channel-option-" + channel}>{channel.stringValue}</option>))
            }
          </select>
          <label style={{marginRight: '10px', color: 'black'}}>Service ID: </label>
          <input type='text' style={{height: '30px', width: '300px', marginRight: '10px'}} onChange={e => setServiceDate({...serviceData, id: e.target.value})}/>
          <input type='button' value={'Search'} onClick={onClickSearch} className="btn btn-primary" style={{minWidth: '100px', padding: '0px', height: '30px', marginBottom: '5px'}}/>
        </div>
      </form>
      <br/>
      {
        serviceData.loading && <Spinner animation="border" role="status" style={{color: 'black'}}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {
        serviceData.message && !serviceData.loading &&
        <div style={{color: 'black'}}>
          {serviceData.message}
        </div>
      }
      {
        serviceData.result && serviceData.result.length > 0 && !serviceData.loading && 
        <Table striped bordered hover responsive>
          <thead>
              <tr>
                <th>Id</th>
                <th>Channel</th>
                <th>Service State</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                serviceData?.result?.map((item, index) => 
                <tr key={"search-item-row-" + index}>
                  <td>{item.id}</td>
                  <td>{item.channel}</td>
                  <td>{item.state}</td>
                  <td>
                  <input disabled={item.state.toLowerCase() === 'closed'} type={'button'} onClick={() => {if (window.confirm('Are you sure you want to close this service?')) onClickClose()}} value='Close' style={{backgroundColor: '#f5e642'}}/>&nbsp;	&nbsp;	&nbsp;	
                  <input disabled={item.state.toLowerCase() === 'closed'} type={'button'} onClick={() => {if (window.confirm('Are you sure you want to force close this service?')) onClickForceClose()}} value='Force Close' style={{backgroundColor: '#ed586e'}}/>
                  </td>
                </tr>)
              }
              
            </tbody>
        </Table>
      }
    </div>
  );
}

export default SearchAsure;
