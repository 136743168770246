import './Login.css';
import { LOGIN_URL } from '../../const'

function Login() {
    const onClickSSO = () => {
        window.location.href = LOGIN_URL;
    }
    return (
        <form className="container">
        <div className="simple-login-container">
            <h2>Please Login Here</h2>
            <div className="row" style={{marginBottom: '10px'}}>
                <div className="col-md-12 form-group">
                    <input type="text" className="form-control" placeholder="Username"/>
                </div>
            </div>
            <div className="row" style={{marginBottom: '10px'}}>
                <div className="col-md-12 form-group">
                    <input type="password" placeholder="Enter your Password" className="form-control"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 form-group">
                    <input type="submit" className="btn btn-block btn-login" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 form-group">
                    <input type="button" className="btn btn-primary" placeholder="Enter your Password" value={`Continue with SSO`} onClick={onClickSSO}/>
                </div>
            </div>
        </div>
        </form>
    );
}

export default Login;
