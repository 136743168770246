import axios from "axios";
import Cookies from 'js-cookie';
import { AUTH_BASE_URL, APP_CLIENT_ID, APP_CLIENT_SECRET, LOGIN_URL, REDIRECT_URI, LOGOUT_URL, currentUserKey, idTokenKey, accessTokenKey } from '../const'
import {encode as base64_encode} from 'base-64';

// const API_URL = "http://localhost:8080/api/auth/";

// const register = (username, email, password) => {
//   return axios.post(API_URL + "signup", {
//     username,
//     email,
//     password,
//   });
// };

// const login = (username, password) => {
//   return axios
//     .post(API_URL + "signin", {
//       username,
//       password,
//     })
//     .then((response) => {
//       if (response.data.accessToken) {
//         localStorage.setItem("user", JSON.stringify(response.data));
//       }

//       return response.data;
//     });
// };

// const logout = () => {
//   localStorage.removeItem("user");
// };

// const getCurrentUser = () => {
//   return JSON.parse(localStorage.getItem("user"));
//   // let user = await Auth.currentAuthenticatedUser();
// };

export function getCurrentUser() {
  try {
    let user = Cookies.get(currentUserKey);
    return user ? user : "{}";
  } catch {
    return "";
  }
}

export function isAuthenticated() {
  return Cookies.get(currentUserKey) ? true : false;
}

export async function setCurrentUser() {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getAccessToken()
     },
     responseType: 'json'
    }

    return axios.get(AUTH_BASE_URL + '/oauth2/userInfo', config);

  }  catch (err) {
    return null;
  }
}

export function setAccessToken(token) {
  try {
    Cookies.set(accessTokenKey, token);
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function getAccessToken() {
  try {
    return Cookies.get(accessTokenKey);
  } catch (err) {
    return null;
  }
}

export function getIdToken() {
  try {
    return Cookies.get(idTokenKey);
  } catch (err) {
    return null;
  }
}

export function logout() {
  try {
    Cookies.remove(idTokenKey);
    Cookies.remove(accessTokenKey);
    Cookies.remove(currentUserKey);
    window.location.href=LOGOUT_URL;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function oauth2Token(code) {
  try {
    if (code) {
      let basicAuthen = base64_encode(`${APP_CLIENT_ID}:${APP_CLIENT_SECRET}`);

      const params = new URLSearchParams();
      params.append('grant_type', 'authorization_code')
      params.append('client_id', APP_CLIENT_ID);
      params.append('code', code)
      params.append('redirect_uri', REDIRECT_URI)

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + basicAuthen
        }
      }
      return axios.post(AUTH_BASE_URL + '/oauth2/token', params, config);
    } else if (!isAuthenticated()) {
      window.location.href=LOGIN_URL;
    }
  } catch (err) {
    return null;
  }
}

// const isAuthenticated = () => {
//   return Auth.isUserLoggedIn();
// }

// export default {
//   register,
//   login,
//   logout,
//   getCurrentUser,
//   isAuthenticated
// };
