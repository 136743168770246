import * as API from "./axios";
import {API_BASE_URL as BASE_URL} from '../const';


export async function searchCaseReports(caseId, vin, msisdn) {
    try {
        let url = BASE_URL + `/case-reports/search?`;
        const queryParams = [];

        if (caseId) queryParams.push(`case-id=${caseId}`);
        if (vin) queryParams.push(`vin=${vin}`);
        if (msisdn) queryParams.push(`msisdn=${msisdn}`);
        url += queryParams.join('&');

        const result = await API.init().get(url);

        if (result && result.data) {
            return result.data;
        }
    } catch (err) {
        return buildErrorResponse(err);
    }
}

export async function downloadCaseReport(caseId) {
    try {
        const result = await API.init().get(BASE_URL + `/case-reports/download?case-id=${caseId}`);
        if (result && result.data) {
            return result.data;
        }
    } catch (err) {
        return buildErrorResponse(err);
    }
}

export async function uploadDelaconReport(formData) {
    try {
        const result = await API.init().post(BASE_URL + `/delacon-reports/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (result && result.data) {
            return result.data;
        }
    } catch (err) {
        return buildErrorResponse(err);
    }
}

function buildErrorResponse(err) {
    let res = err?.response;
    return {
        'message': res?.data?.message,
        'status': 'ERROR',
        'statusCode': res?.status
    }
}

const CaseReportsService = {
    searchCaseReports,
    downloadCaseReport
};

export default CaseReportsService;
