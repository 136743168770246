import React from 'react';

class PageNotFound extends React.Component {
  render() {
    return (
      <div>
        The page does not exist.
      </div>
    );
  }
}

export default PageNotFound;
