import * as API from "./axios";
import {API_BASE_URL as BASE_URL} from '../const';


export async function searchSVTTrackingReports(channel,start_datetime,end_datetime) {
    try {
        const result = await API.init().get(BASE_URL + `/svt-tracking-reports/search?channel=${channel}&start_datetime=${start_datetime}&end_datetime=${end_datetime}`);
        if (result && result.data) {
            return result.data;
        }
    } catch (err) {
        return buildErrorResponse(err);
    }
}

export async function downloadSVTTrackingReports(channel,start_datetime,end_datetime) {
    try {
        const result = await API.init().get(BASE_URL + `/svt-tracking-reports/download?channel=${channel}&start_datetime=${start_datetime}&end_datetime=${end_datetime}`);
        if (result && result.data) {
            return result.data;
        }
    } catch (err) {
        return buildErrorResponse(err);
    }
}

function buildErrorResponse(err) {
    let res = err?.response;
    return {
        'message': res?.data?.message,
        'status': 'ERROR',
        'statusCode': res?.status
    }
}

const SVTTrackingReportsService = {
    searchSVTTrackingReports,
    downloadSVTTrackingReports
};

export default SVTTrackingReportsService;
