import { Spinner, Table } from 'reactstrap';
import './ReportDownload.css';
import React, { useEffect, useState } from 'react';
import AsureServices from '../../../services/asure.service';
var FileSaver = require('file-saver');
const reportTypes = ['TMCA', 'HMCA', 'GMA', 'MMAL', 'Delacon', 'Call Time SLA'];
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const years = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032' ,'2033'];

const initialState = {
  selectedType: 'TMCA',
  selectedYear: '',
  selectedMonth: '',
  returnType: '',
  returnPeriod: '',
  returnStatus: '',
  loading: false,
  isEnabledPerformanceMetrics: false,
  report: ''
};

function ReportDownload() {
  const [reportState, setReportState] = useState(initialState);

  useEffect(() => {
    const d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    if (parseInt(month) < 10) month = '0' + parseInt(month);
    setReportState({...reportState, selectedMonth: month, selectedYear: year});
  }, []);

  const onClickRunReport = () => {
    setReportState({...reportState, report:'', loading: true});
    AsureServices.findMonthlyReport(reportState.selectedType, reportState.selectedYear, reportState.selectedMonth).then(res => {
    setReportState({...reportState, loading: false, returnStatus: res.status, report: res.data, returnType: res.type, returnPeriod: res.period, isEnabledPerformanceMetrics: res.isEnabledPerformanceMetrics})
    });
  }

  const onChangeMonth = (event) => {
    let val = event.target.value;
    if (parseInt(val) < 10) val = '0' + parseInt(val);
    setReportState({...reportState, selectedMonth: val});
  }

  const downloadReport = () => {
    if (reportState.returnType === 'Delacon') {
      var blob = new Blob([reportState.report], {type : 'text/csv'});
      FileSaver.saveAs(blob, `${reportState.selectedYear}${reportState.selectedMonth}-assist-report.csv`);
    } else if (reportState.returnType === 'Call Time SLA') {
        var blob = new Blob([reportState.report], {type : 'text/csv'});
        FileSaver.saveAs(blob, `${reportState.selectedYear}-${reportState.selectedMonth}-call-time-sla-report.csv` );
    } else {
      if (reportState.isEnabledPerformanceMetrics) {
        var blob = new Blob([reportState.report], {type: 'text/plain'});
        FileSaver.saveAs(blob, `${reportState.returnType}-${reportState.selectedYear}-${reportState.selectedMonth}-report_v2.txt`);
      } else {
        var blob = new Blob([reportState.report], {type: 'text/plain'});
        FileSaver.saveAs(blob, `${reportState.returnType}-${reportState.selectedYear}-${reportState.selectedMonth}-report.txt`);
      }
    }
  }

  return (
    <div className="provision-device-container">
      <form>
        <h2 style={{backgroundColor: 'rgb(197, 217, 255)', marginBottom: '1rem'}}>Monthly Report</h2>
        <br/>
        <div>
          <label style={{marginRight: '10px'}}>Type: </label>
          <select style={{marginRight: '10px', height: '30px', minWidth: '70px'}} onChange={e => setReportState({...reportState, selectedType: e.target.value})}>
            {
              reportTypes?.map(t => <option key={t}>{t}</option>)
            }
          </select>
          <label style={{marginRight: '10px'}}>Year: </label>
          <select style={{marginRight: '10px', height: '30px', minWidth: '70px'}} value={reportState.selectedYear} onChange={e => setReportState({...reportState, selectedYear: e.target.value})}>
            {
              years?.map(y => <option value={y} key={y}>{y}</option>)
            }
          </select>
          <label style={{marginRight: '10px'}}>Month: </label>
          <select style={{marginRight: '10px', height: '30px', minWidth: '70px'}} value={reportState.selectedMonth} onChange={onChangeMonth} >
            {
              months?.map(m => <option value={m} key={m}>{m}</option>)
            }
          </select>
          <button className="btn btn-primary" style={{minWidth: '100px', padding: '0px', height: '30px', marginBottom: '5px'}} type='button' onClick={onClickRunReport}>Search</button>
        </div>
      </form>
      {
        reportState.loading && <Spinner animation="border" role="status" style={{margin: '0 auto'}}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {
        reportState.report && !reportState.loading &&
        <div>
            <br/>
            <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Period</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>{reportState.returnType}</td>
                    <td>{reportState.returnPeriod}</td>
                    <th>{reportState.returnStatus}</th>
                    <td><input disabled={reportState.returnStatus !== 'READY'} onClick={downloadReport} className="btn btn-primary" type={'button'} value='Download' style={{lineHeight: '20px'}}/></td>
                    </tr>
                </tbody>
            </Table>
        </div>
      }

    </div>
  );
}

export default ReportDownload;
