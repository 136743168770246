import './Home.css';
import * as AuthService from "../../services/auth.service";
import React, { useState } from 'react'

function Home() {
  const [currentUser, setCurrentUser] = useState(JSON.parse(AuthService.getCurrentUser()));

  return (
    <div className="home-page-container ">

      Welcome back, {currentUser.email}
      {/* <div style={{width: '200px', margin: '0 auto'}}>
        <Row>
            <Button color="info">Provision Devives</Button>{' '}
        </Row>
        <br/>
        <Row>
            <Button color="info">Existing client</Button>{' '}
        </Row>
        <br/>
        <Row>
            <Button color="info">New client or New Fleet</Button>{' '}
        </Row>
        <br/>
        <Row>
            <Button color="info">Manage Devices</Button>{' '}
        </Row>
        <br/>
        <Row>
            <Button color="info">Search Device</Button>{' '}
        </Row>
      </div> */}
    </div>
  );
}

export default Home;
