import React from "react";
import { Outlet } from "react-router-dom";
import { LOGIN_URL } from "../const";
import AsureServices from "../services/asure.service";

export default function AuthenticatedRoute({ isAuthenticated }) {
  AsureServices.ping();
  return isAuthenticated
    ? <Outlet />
    : window.location.href=LOGIN_URL;
};
